import { useEffect, useState } from 'react';

import { GetApiState } from 'common/types';
import { AuthRole, useUser } from 'context/AuthContext';
import { useDashboardBff } from 'hooks/useDashboardBff';

export type Response = {
  apiState: GetApiState;
  hasNominationAccess: boolean;
  error?: unknown;
};

export default function useNominationAccess(): Response {
  const { apiClient: dashboardBffApiClient } = useDashboardBff();

  const { roles } = useUser();

  const [hasNominationAccess, setHasNominationAccess] = useState(false);

  const [error, setError] = useState<unknown>();

  const [apiState, setApiState] = useState<GetApiState>(GetApiState.NONE);

  useEffect(() => {
    if (roles.has(AuthRole.OPERATIONS)) {
      setHasNominationAccess(true);
      return;
    }

    setApiState(GetApiState.FETCHING);

    const url = '/coachees/can-nominate';

    dashboardBffApiClient()
      .post<boolean>(url)
      .then(({ data }) => {
        setHasNominationAccess(data);
        setApiState(GetApiState.FETCHED);
        setError(undefined);
      })
      .catch((error) => {
        setHasNominationAccess(false);
        setApiState(GetApiState.ERROR);
        setError(error);
      });
  }, []);

  return {
    error,
    apiState,
    hasNominationAccess,
  };
}
