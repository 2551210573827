/* eslint-disable @typescript-eslint/no-use-before-define */
/*
    SESSION
 */

export type Session = {
  id: string;
  scheduledAt: string;
  scheduledVia: ScheduledVia;
  startsAt: string;
  endsAt: string;
  attachment?: object;
  rating?: object[];
  entryPoints?: object[];
  status: keyof typeof SessionStatus;
  subStatus: keyof typeof SessionSubStatus;
  coach: User;
  coachee: User;
};

/*
    COACHING
 */
export type FocusArea = {
  id: string;
  title: string;
  description: string;
  order: string;
  headerImage: string;
  icon: string;
  labels: string[];
  labelText: string;
  categoryId: string;
  categoryText: string;
};

export enum ScheduledVia {
  Coachhub = 'COACHHUB',
  External = 'EXTERNAL',
}

export enum SessionStatus {
  PROPOSED = 'PROPOSED',
  SCHEDULED = 'SCHEDULED',
  CANCELLED = 'CANCELLED',
  COMPLETED = 'COMPLETED',
  UNATTENDED = 'UNATTENDED',
}

export enum SessionSubStatus {
  BY_COACH = 'BY_COACH',
  BY_COACHEE = 'BY_COACHEE',
  BY_COACH_PROPOSAL = 'BY_COACH_PROPOSAL',
  BY_COACHEE_PROPOSAL = 'BY_COACHEE_PROPOSAL',
  BY_COACHEE_LATE_CANCEL = 'BY_COACHEE_LATE_CANCEL',
  BY_COACHEE_RESCHEDULE = 'BY_COACHEE_RESCHEDULE',
  BY_BOTH = 'BY_BOTH',
  VIA_COACHHUB = 'VIA_COACHHUB',
  VIA_EXTERNAL = 'VIA_EXTERNAL',
  TO_INVESTIGATE = 'TO_INVESTIGATE',
}

type User = {
  userId: string;
  timezone: string;
};

export type Ratings = {
  focusAreaId: string;
  rating: string;
  ratedAt: Date;
  orgId: string;
  coacheeId: string;
};

export type FocusAreas = {
  id: string;
  name: string;
};

export type TopStrength = {
  id: string;
  orgId: string;
  assessmentId: string;
  coachingFrameworkId: string;
  assessmentResultId: string;
  focusAreaId: string;
  coacheeId: string;
  value: string;
};

export type FeedbackMessage = {
  id: string;
  message: string;
  userId: string;
  organizationId: string;
  isSelected: boolean;
};

export type PeriodicRatings = {
  startDate: string;
  focusAreaId: string;
  ratingAvg: number;
};

export enum GetApiState {
  NONE,
  FETCHING,
  FETCHED,
  ERROR,
}

export enum SubmitApiState {
  NONE,
  PROCESSING,
  SUCCESS,
  ERROR,
}

export type ApiErrorResponse = {
  code: string;
  id: string;
  isInternal: boolean;
  message: string;
  statusCode: number;
  timestamp: string;
};