import React from 'react';

import { Button } from '@coachhubio/nova-button';
import { UserPlusIcon } from '@coachhubio/nova-icon';
import useFetchPrograms from 'hooks/useFetchPrograms';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import useNominationAccess from 'screens/NominateCoachee/hooks/useNominationAccess';

export const NominateAction = () => {
  const router = useRouter();

  const { t } = useTranslation('Programs');

  const { hasNominationAccess } = useNominationAccess();

  const { programs } = useFetchPrograms();

  return (
    <>
      {hasNominationAccess && programs.length > 0 && (
        <Button
          size="s"
          onClick={() => {
            router.push('/nominate-coachee');
          }}
          variant="cta"
        >
          <UserPlusIcon size="m" />

          {t('nominateButton')}
        </Button>
      )}
    </>
  );
};
