import React from 'react';

import { Toast } from 'components/Toast';

import { AppBar } from './components/AppBar';

const AuthenticatedApp = ({ children }: { children: React.ReactNode }) => {
  return (
    <>
      <AppBar />
      {children}
      <Toast />
    </>
  );
};

export default AuthenticatedApp;
