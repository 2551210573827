import { gql } from '@apollo/client';

import { CHILDREN_RECURSIVE } from './fragments';

export const LIST_GLOBAL_MEMBERS = gql`
  query listMembers($input: ListMemberInput) {
    listMembers(input: $input) {
      ... on MemberConnection {
        edges {
          tenantId
          node {
            userId
          }
        }
      }
    }
  }
`;

export const LIST_ACCOUNTS_QUERY = gql`
  query ListAccounts($input: ListAccountInput) {
    listAccounts(input: $input) {
      ... on AccountConnection {
        edges {
          cursor
          node {
            id
            parentId
            rootId
            tenantId
            name
            coacheeNominationAccess
          }
        }
      }
    }
  }
`;

export const LIST_ACCOUNTS_BASIC_QUERY = gql`
  query listAccountsBasicData($input: ListAccountInput) {
    listAccounts(input: $input) {
      ... on AccountConnection {
        edges {
          cursor
          node {
            id
            parentId
            tenantId
            name
          }
        }
      }
    }
  }
`;

export const LIST_ACCOUNTS_ROOT_ID_QUERY = gql`
  query ListAccountsRootId($input: ListAccountInput) {
    listAccounts(input: $input) {
      ... on AccountConnection {
        edges {
          cursor
          node {
            coacheeNominationAccess
            rootId
          }
        }
      }
    }
  }
`;

export const GET_ACCOUNT_TREE_QUERY = gql`
  query getAccountTree($rootId: ID!) {
    getAccountTree(input: { rootId: $rootId }) {
      ...ChildrenRecursive
    }
  }
  ${CHILDREN_RECURSIVE}
`;

export const GET_PROGRAM_FROM_ACCOUNT = gql`
  query getProgramFromAccount($input: GetProgramInput) {
    getProgram(input: $input) {
      coachingClassification
      createdAt
      description
      duration
      id
      name
      isAICompanionEnabled
      accountId
      accountGrants {
        accountId
        id
        programId
      }
      priceClassification
      sessions
      type
      subType
      enableLineManagerInvolvement
      updatedAt
      coachId
    }
  }
`;

export const LIST_PROGRAMS_FROM_ACCOUNT = gql`
  query ListProgramsFromAccount($input: ListProgramsInput) {
    listPrograms(input: $input) {
      coachingClassification
      createdAt
      description
      duration
      id
      name
      accountId
      accountGrants {
        accountId
        id
        programId
      }
      priceClassification
      sessions
      updatedAt
      coachId
      type
      subType
      enableLineManagerInvolvement
      hasSessionAllocated
      coachingGroupInfo {
        seatsLeft
        seatsUsed
        endsAt
        startsAt
      }
    }
  }
`;

export const GET_ACCOUNT = gql`
  query getAccount($input: GetAccountInput) {
    getAccount(input: $input) {
      id
      lookerDashboardId
      name
      parentId
      rootId
      tenantId
    }
  }
`;

export const UPDATE_PROGRAM_ACCOUNT = gql`
  mutation updateProgramAccount($input: UpdateProgramInput) {
    updateProgram(input: $input) {
      accountGrants {
        accountId
        id
        programId
      }
      accountId
      accountRootId
      coachingClassification
      createdAt
      description
      duration
      id
      name
      priceClassification
      sessions
      updatedAt
      coachId
      enableLineManagerInvolvement
    }
  }
`;

export const CREATE_PROGRAM_ACCOUNT = gql`
  mutation createProgramAccount($input: CreateProgramInput) {
    createProgram(input: $input) {
      id
    }
  }
`;

export const UNLINK_BUSINESS_UNIT = gql`
  mutation unlinkAccount($input: UnlinkAccountInput) {
    unlinkAccount(input: $input) {
      id
      lookerDashboardId
      name
      parentId
      rootId
      tenantId
    }
  }
`;

export const LINK_ACCOUNT = gql`
  mutation linkAccount($input: LinkAccountInput) {
    linkAccount(input: $input) {
      id
      lookerDashboardId
      name
      parentId
      rootId
      tenantId
    }
  }
`;

export const GET_AVAILABLE_SEATS_FROM_ACCOUNT = gql`
  query getAvailableSeatsFromAccount($input: GetAvailableSeatsInput) {
    getAvailableSeats(input: $input)
  }
`;

export const UPDATE_ACCOUNT_CONFIG = gql`
  mutation updateAccountConfig($input: UpdateAccountConfigInput) {
    updateAccountConfig(input: $input) {
      id
    }
  }
`;

export const GET_ACCOUNT_CONFIG = gql`
  query getAccountConfig($input: GetAccountInput) {
    getAccount(input: $input) {
      engagementType
      coacheeNominationAccess
      isManualMappingEnabled
      id
    }
  }
`;

export const CREATE_COACH_BRIEFING = gql`
  mutation createCoachBriefing($input: CreateCoachBriefingInput) {
    createCoachBriefing(input: $input) {
      id
    }
  }
`;

export const GET_COACH_BRIEFING = gql`
  query getCoachBriefing($input: GetCoachBriefingInput) {
    getCoachBriefing(input: $input) {
      id
      createdAt
      fileUri
      fileUploadedAt
    }
  }
`;

export const DELETE_COACH_BRIEFING = gql`
  mutation deleteCoachBriefing($input: DeleteCoachBriefingInput) {
    deleteCoachBriefing(input: $input) {
      id
    }
  }
`;

export const UPDATE_COACH_BRIEFING = gql`
  mutation updateCoachBriefing($input: UpdateCoachBriefingInput) {
    updateCoachBriefing(input: $input) {
      id
      fileId
    }
  }
`;

export const LIST_PROGRAM_MANAGERS = gql`
  query listProgramManagers($input: ListProgramManagersInput) {
    listProgramManagers(input: $input) {
      ... on ProgramManagerConnection {
        edges {
          node {
            id
            mail
            name {
              first
              last
            }
            phone
          }
        }
      }
    }
  }
`;

export const CAN_CREATE_PROGRAM = gql`
  query canCreateProgram($input: CanCreateProgramInput) {
    canCreateProgram(input: $input)
  }
`;
